import React from 'react';
// Component imports
import { Text } from '@utils';
import { GatsbyImage } from 'gatsby-plugin-image';

interface Props {
  name: string;
  position: string;
  image: any;
}

const TeamMember: React.FC<Props> = ({ name, position, image }) => {
  return (
    <div className="md:p-10 rounded-2xl p-6 space-y-4 text-center bg-gray-700">
      <GatsbyImage
        image={image}
        alt={name}
        objectFit="cover"
        className="w-28 h-28 sm:h-32 md:h-40 sm:w-32 md:w-40 mx-auto rounded-full"
      />
      <Text style="h3" tag="h2">
        {name}
      </Text>
      <Text style="h5" tag="h3" className="opacity-70 italic">
        {position}
      </Text>
    </div>
  );
};

export default TeamMember;
