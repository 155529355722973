import React from 'react';

import { PageProps } from 'gatsby';
import { graphql } from 'gatsby';

// Component imports
import { Section, Text } from '@utils';
import TeamMember from '@component/Team/TeamMember';
import SEO from '@component/SEO';
import Reveal from '@global/Reveal';
import CTA from '@component/CTA';

const Team: React.FC<PageProps> = ({ data }: any) => {
  return (
    <>
      <SEO
        title="Team"
        path="/team"
        description="Coral Dev has a multi-talented team members with years of combined experience in Minecraft plugins development. Interested in joining our team? Reach out to hello@coraldev.net! "
      />

      <Section id="team">
        <div className="md:space-y-12 lg:space-y-24 space-y-8">
          <Text tag="h1" className="text-center" style="h2">
            <span className="bg-clip-text bg-gradient-to-r from-coral to-reef text-transparent">
              Our Team
            </span>
          </Text>
          <div className="lg:space-y-16 space-y-12">
            <div className="md:justify-start flex items-center justify-center space-x-4">
              <div className="bg-gradient-to-r from-reef to-coral inline-block w-16 h-1 rounded-full"></div>
              <Text style="h3" tag="h3">
                Management Team
              </Text>
            </div>
            <div className="md:grid-cols-2 lg:grid-cols-3 lg:gap-16 grid gap-8">
              <Reveal stagger={0.25}>
                <TeamMember
                  image={data.jim.avatar.asset.gatsbyImageData}
                  name={data.jim.name}
                  position={data.jim.position}
                />
                <TeamMember
                  image={data.max.avatar.asset.gatsbyImageData}
                  name={data.max.name}
                  position={data.max.position}
                />
                <TeamMember
                  image={data.shaun.avatar.asset.gatsbyImageData}
                  name={data.shaun.name}
                  position={data.shaun.position}
                />
              </Reveal>
            </div>
          </div>
          <div className="lg:space-y-16 space-y-12">
            <div className="md:justify-start flex items-center justify-center space-x-4">
              <div className="bg-gradient-to-r from-reef to-coral inline-block w-16 h-1 rounded-full"></div>
              <Text style="h3" tag="h3">
                Development Team
              </Text>
            </div>
            <div className="md:grid-cols-2 lg:grid-cols-3 lg:gap-16 grid gap-8">
              {data.developers.nodes.map((Member: any, index: any) => (
                <Reveal stagger={0.25}>
                  <TeamMember
                    key={index}
                    image={Member.avatar.asset.gatsbyImageData}
                    name={Member.name}
                    position={Member.position}
                  />
                </Reveal>
              ))}
            </div>
          </div>
          <div className="lg:space-y-16 space-y-12">
            <div className="md:justify-start flex items-center justify-center space-x-4">
              <div className="bg-gradient-to-r from-reef to-coral inline-block w-16 h-1 rounded-full"></div>
              <Text style="h3" tag="h3">
                Contributors
              </Text>
            </div>
            <div className="md:grid-cols-2 lg:grid-cols-3 lg:gap-16 grid gap-8">
              {data.contributors.nodes.map((Member: any, index: any) => (
                <Reveal stagger={0.25}>
                  <TeamMember
                    key={index}
                    image={Member.avatar.asset.gatsbyImageData}
                    name={Member.name}
                    position={Member.position}
                  />
                </Reveal>
              ))}
            </div>
          </div>
        </div>
      </Section>
      <CTA />
    </>
  );
};

export const query = graphql`
  {
    developers: allSanityTeamMember(filter: { position: { eq: "Developer" } }) {
      nodes {
        name
        position
        avatar {
          asset {
            gatsbyImageData(
              fit: FILLMAX
              placeholder: BLURRED
              width: 160
              height: 160
            )
          }
        }
      }
    }
    contributors: allSanityTeamMember(
      filter: { position: { eq: "Contributor" } }
    ) {
      nodes {
        name
        position
        avatar {
          asset {
            gatsbyImageData(
              fit: FILLMAX
              placeholder: BLURRED
              width: 160
              height: 160
            )
          }
        }
      }
    }
    shaun: sanityTeamMember(name: { eq: "Shaun" }) {
      name
      position
      avatar {
        asset {
          gatsbyImageData(
            fit: FILLMAX
            placeholder: BLURRED
            width: 160
            height: 160
          )
        }
      }
    }
    max: sanityTeamMember(name: { eq: "Max" }) {
      name
      position
      avatar {
        asset {
          gatsbyImageData(
            fit: FILLMAX
            placeholder: BLURRED
            width: 160
            height: 160
          )
        }
      }
    }
    jim: sanityTeamMember(name: { eq: "Jim" }) {
      name
      position
      avatar {
        asset {
          gatsbyImageData(
            fit: FILLMAX
            placeholder: BLURRED
            width: 160
            height: 160
          )
        }
      }
    }
  }
`;

export default Team;
